import { createWebHistory, createRouter } from "vue-router";
import store from "../vuex/store.js";
import GlobalHome from "@/views/GlobalHome.vue";
import { nextTick } from "vue";

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated) {
        next();
        return;
    }
    next("/");
};

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next();
        return;
    }
    next("/auth/login");
};

const isAdmin = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        if (store.getters.isAdmin) {
            next();
            return;
        }
    }
    next("/auth/login");
};

const ForgotPassword = () => import("@/components/auth/ForgotPassword.vue");
const ResetPasswordForm = () =>
    import("@/components/auth/ResetPasswordForm.vue");
const Home = () => import("@/views/pages/Home.vue");

const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
        meta: {
            title: "Home",
        },
    },
    {
        path: "/about",
        name: "about",
        component: () => import("@/views/pages/About.vue"),
        meta: {
            title: "About",
        },
    },
    {
        path: "/auth/google/callback",
        component: {
            template: '<div class="auth-component"></div>',
        },
        meta: {
            title: "Callback",
        },
    },
    {
        path: "/privacy-policy",
        name: "Privacy Policy",
        component: () => import("@/views/pages/PrivacyPolicy.vue"),
        meta: {
            title: "سياسة الخصوصية",
        },
    },
    {
        path: "/user-data-deletion",
        name: "UserDataDeletion",
        component: () => import("@/views/pages/UserDataDeletion.vue"),
        meta: {
            title: "User Data Deletion",
        },
    },
    {
        path: "/contact-us",
        name: "Contact us",
        component: () => import("@/views/pages/ContactUs.vue"),
        meta: {
            title: "تواصل معنا",
        },
    },
    {
        path: "/reset-password",
        name: "reset-password",
        component: ForgotPassword,
        meta: {
            title: "Reset Password",
            auth: false,
        },
    },
    {
        path: "/reset-password/:token",
        name: "reset-password-form",
        component: ResetPasswordForm,
        meta: {
            title: "Reset Password Form",
            auth: false,
        },
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
            title: "Dashboard",
        },
    },

    {
        path: "/admin",
        component: () => import("@/views/pages/admin/Admin.vue"),
        children: [
            {
                path: "users",
                name: "users",
                beforeEnter: isAdmin,
                component: () => import("@/views/pages/admin/Users.vue"),
                meta: {
                    title: "Users",
                    requiresAuth: true,
                },
            },
            {
                path: "cfm",
                name: "CFM",
                beforeEnter: isAdmin,
                component: () => import("@/views/pages/admin/CFM.vue"),
                meta: {
                    title: "CFM",
                    requiresAuth: true,
                },
            },
            {
                path: "Lists",
                name: "Lists",
                // beforeEnter: isAdmin,
                component: () => import("@/views/pages/admin/lists.vue"),
                meta: {
                    title: "Lists",
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: "/admin/controllers",
        name: "Blogs",
        // beforeEnter: isAdmin,
        component: () =>
            import("@/views/pages/admin/controllers/controllers.vue"),
        meta: {
            title: "Blogs",
            requiresAuth: true,
        },
        children: [
            {
                path: "blogs",
                name: "Blogs",
                // beforeEnter: isAdmin,
                component: () =>
                    import("@/views/pages/admin/controllers/Blogs.vue"),
                meta: {
                    title: "Blogs",
                    requiresAuth: true,
                },
            },
            {
                path: "blogs/:type/:id?",
                name: "CUBlogs",
                // beforeEnter: isAdmin,
                component: () =>
                    import("@/views/pages/admin/controllers/CUBlog.vue"),
                props: true,
                meta: {
                    title: "CUBlog",
                    requiresAuth: true,
                },
            },
            {
                path: "facebook-graph-handler",
                name: "Facebook Graph Handler",
                // beforeEnter: isAdmin,
                component: () =>
                    import("@/views/pages/admin/controllers/FacebookGraphHandler.vue"),
                props: true,
                meta: {
                    title: "Facebook Graph Handler",
                    requiresAuth: true,
                },
            },
            {
                path: "dashboard",
                name: "Dashboard",
                // beforeEnter: isAdmin,
                component: () =>
                    import("@/views/pages/admin/controllers/Dashboard.vue"),
                meta: {
                    title: "Dashboard",
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: "/landing",
        name: "landing",
        component: () => import("@/views/pages/Landing.vue"),
    },
    {
        path: "/auth/login",
        name: "login",
        beforeEnter: ifNotAuthenticated,
        component: () => import("@/views/pages/auth/Login.vue"),
        meta: {
            title: "Login",
        },
    },
    {
        path: "/auth/register",
        name: "register",
        beforeEnter: ifNotAuthenticated,
        component: () => import("@/views/pages/auth/Register.vue"),
        meta: {
            title: "Register",
        },
    },
    {
        path: "/auth/access",
        name: "accessDenied",
        component: () => import("@/views/pages/auth/Access.vue"),
        meta: {
            title: "Access Denied",
        },
    },
    {
        path: "/auth/error",
        name: "error",
        component: () => import("@/views/pages/auth/Error.vue"),
        meta: {
            title: "Error",
        },
    },
    {
        path: "/categories",
        name: "categories",
        component: () => import("@/views/pages/Categories.vue"),
        meta: {
            title: "Categories",
        },
        children: [
            {
                path: ":slug",
                name: "subcategories",
                component: () => import("@/views/pages/SubCategory.vue"),
                meta: {
                    title: "Subcategories",
                },
                children: [],
            },
        ],
    },
    {
        path: "/posts",
        name: "blog",
        component: () => import("@/views/pages/NewBlogs.vue"),
        meta: {
            title: "Blog",
            watchParam: "slug",
        },
    },
    {
        path: "/posts/:slug",
        name: "blog",
        component: () => import("@/views/pages/Blog.vue"),
        meta: {
            title: "Blog",
            watchParam: "slug",
        },
    },
    {
        path: "/new-blogs",
        name: " newBlogs",
        component: () => import("@/views/pages/NewBlogs.vue"),
        meta: {
            title: "New Blogs",
        },
    },
    {
        path: "/trending-blogs",
        name: " trendingBlogs",
        component: () => import("@/views/pages/TrendingBlogs.vue"),
        meta: {
            title: "Trending Blogs",
        },
    },
    {
        path: "/posts/search/:slug",
        name: "search",
        component: () => import("@/views/pages/Search.vue"),
        meta: {
            title: "Search",
        },
    },
    // {
    //     path: "/search",
    //     name: "search",
    //     component: () => import("@/views/pages/AutoComplete.vue"),
    //     meta: {
    //         title: "AutoComplete Search",
    //     },
    // },
    {
        path: "/:pathMatch(.*)*",
        name: "notfound",
        component: () => import("@/views/pages/NotFound.vue"),
        meta: {
            title: "الصفحة غير موجودة",
        },
    },
    // {
    //     path: "/not-found",
    //     name: "notfound",
    //     component: () => import("@/views/pages/NotFound.vue"),
    //     meta: {
    //         title: "Not Found",
    //     },
    // },
];

// Use
const router = new createRouter({
    history: createWebHistory(),
    routes,
    hashbang: false,
});

// Dynamically Change Page Title
const DEFAULT_TITLE = "Touta World";
router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.getters.isAuthenticated && !store.getters.isAdmin) {
            next({
                path: "/auth/login",
                query: { redirect: to.fullPath },
            });
        } else {
            next();
        }
    } else {
        next(); // make sure to always call next()!
    }
});

export default router;
